import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Blog from "./Pages/Blog";
import WhereToGo from "./Pages/WhereToGo";
import WhereToGoComponent from "./Pages/WhereToGoComponent";
import BlogComponent from "./Pages/BlogComponent";
import Rental from "./Pages/Rental";
import TripPlanning from "./Pages/TripPlanning";
import TripPlanningComponent from "./Pages/TripPlanningComponent";
import Account from "./Pages/Account";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import Cart from "./Pages/Cart";
import EditAccount from "./Pages/EditAccount";
import Header from "./Components/Header";
import Main from "./Pages/Main";
import Footer from "./Components/Footer";

function App() {
  const [user, setUser] = useState({})

  useEffect(() => {
    const fetchData = async () => {
        if(localStorage.getItem('email') && localStorage.getItem('hash')){
            try {
                const response = await fetch('https://adm.trevatour.ru/api/website/getuserdata.php?email=' + localStorage.getItem('email') + '&hash=' + localStorage.getItem('hash'));
                const responseData = await response.json();
                if(!responseData?.error){
                    setUser(responseData);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    fetchData();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header user={user} />
        <Routes>
          <Route index element={<Main/>} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Blog/:id" element={<BlogComponent />} />
          <Route path="/WhereToGo" element={<WhereToGo />} />
          <Route path="/WhereToGo/:id" element={<WhereToGoComponent />} />
          <Route path="/Rental" element={<Rental user={user} setUser={setUser} />} />
          <Route path="/TripPlanning" element={<TripPlanning user={user} setUser={setUser} />} />
          <Route path="/TripPlanning/:id" element={<TripPlanningComponent user={user} setUser={setUser} />} />
          <Route path="/Account" element={<Account user={user} setUser={setUser} />} />
          <Route path="/EditAccount" element={<EditAccount user={user} setUser={setUser} />} />
          <Route path="/SignUp" element={<SignUp user={user} setUser={setUser} />} />
          <Route path="/SignIn" element={<SignIn setUser={setUser} />} />
          <Route path="/Cart" element={<Cart user={user} setUser={setUser} />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
