import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SignIn({setUser}){
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const signIn = async () => {
        if(email.length > 0 && password.length > 0) {
            try {
                const response = await fetch('https://adm.trevatour.ru/api/website/signin.php?email=' + email + '&password=' + password);
                const responseData = await response.json();
                if(!responseData?.error){
                    localStorage.setItem('email', responseData?.email)
                    localStorage.setItem('hash', responseData?.hash)
                    setUser(responseData)
                    navigate('/Account')
                }else{
                    setError(responseData?.error);
                }
            } catch (error) {
                setError(error)
            }
        }else {
            setError('Все поля обязательны к заполнению!')
        }
    };

    return(
        <div>
            <div style={{backgroundImage: `url(${require('../Images/Main1.jpg')}`, backgroundPosition: 'top', backgroundColor: '#d6d2c9', padding: '0px'}} className='Section'>
                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '150px 0px', alignItems: 'center', WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)'}}>
                    <h2 style={{color: 'white', fontFamily: 'Nevduplenysh-Regular', fontSize: 'calc(3vw + 40px)', lineHeight: 'calc(3vw + 40px)'}} className='Title'>Войти в аккаунт</h2>
                    <form style={{width: '400px', display: 'flex', flexDirection: 'column', marginTop: '50px', rowGap: '40px', maxWidth: '100%'}}>                   
                        <input onChange={(e) => setEmail(e.target.value)} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Email" type="email" />
                        <input onChange={(e) => setPassword(e.target.value)} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Пароль" type="password" />
                        {error && <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p>}
                        <a onClick={signIn} style={{color: 'black', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', maxWidth: '100%', width: '400px', textAlign: 'center', backgroundColor: 'white', cursor: 'pointer'}}>Войти</a>
                        <a href='/SignUp' style={{color: 'white'}}>Регистрация</a>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignIn