import React, { useState, useEffect } from 'react';

function Blog() {
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            try {
              const response = await fetch('https://adm.trevatour.ru/api/website/getbloglist.php');
              const responseData = await response.json();
              setBlogs(responseData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
      
        fetchData();
    }, [])
    
    return (
        <div>
            <div style={{backgroundImage: `url(${require('../Images/Blog.png')}`, backgroundPosition: 'bottom'}} className='Section'>
                <div className='Padding1'>
                    
                </div>
                <div style={{width: 512, height: '100%', maxWidth: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 50}}>
                    <h2 style={{color: 'white', textAlign: 'left'}} className='Title'>Блог</h2>
                    <p style={{textAlign: 'left'}} className='Description_Bold'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
            </div>
            <div style={{backgroundColor: '#d6d2c9', flexDirection: 'row', gap: '30px', alignItems: 'flex-start', justifyContent: 'space-evenly', flexWrap: 'wrap', padding: '60px 0px'}} className='Section'>
                {
                    blogs.map((blog, index) => {
                        return(
                            <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                                <div style={{backgroundImage: `url(${blog?.image_urls.split('#')[0]})`, backgroundPosition: 'bottom', padding: '0px 0px', minHeight: '300px'}} className='Section'></div>
                                <div style={{padding: '10px 0px 5px 0px', backgroundColor: '#4A6118', width: '100%', textAlign: 'center'}}>
                                    <a href={`Blog/${blog?.id}`} style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', textDecoration: 'none'}}>ЧИТАТЬ</a>
                                </div>
                                <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', width: 'calc(100% - 40px)', marginTop: 10}} className='Description_Bold'>{blog?.title}</p>
                                <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', width: 'calc(100% - 40px)', margin: '10px 0px'}} className='Description'>{blog?.short_description}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Blog;