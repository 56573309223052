import React, { useState, useEffect } from "react";
import TeamSlider from "../Components/TeamSlider";
import { useParams, useNavigate } from "react-router-dom";

function TripPlanningComponent({user, setUser}){
    const [trip, setTrip] = useState(null)
    const [team, setTeam] = useState([])
    const navigate = useNavigate()
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            try {
                const tripResponse = await fetch('https://adm.trevatour.ru/api/website/gettrip.php?id=' + id);
                const tripResponseData = await tripResponse.json();
                setTrip(tripResponseData);
                const teamResponse = await fetch('https://adm.trevatour.ru/api/website/getteam.php');
                const teamResponseData = await teamResponse.json();
                setTeam(teamResponseData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
      
        fetchData();
    }, [])

    const book = async (quantity, id, max_count) => {
        if (user.email && user.hash) {
            try {
                const formData = new FormData();
                formData.append('email', user?.email);
                formData.append('hash', user?.hash);
                formData.append('quantity', quantity);
                formData.append('id', id);
                formData.append('max_count', max_count);
    
                const response = await fetch('https://adm.trevatour.ru/api/website/changetriptocart.php', {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    alert('Oшибка при добавлении товара в карзину!');
                }
    
                const responseData = await response.json();
    
                if (!responseData || responseData.error) {
                    alert('Oшибка при добавлении товара в карзину!');
                }else{
                    var updated_user = {...user}
                    updated_user.trips_cart = responseData?.rental || '[]'
                    setUser(updated_user)
                    navigate('/Cart')
                }
            } catch (error) {
                alert(error.message);
            }
        } else {
            navigate('/SignIn');
        }
    };

    return(
        trip != null ?
        <div>
            <div style={{backgroundImage: `url(${trip.image_url}`, backgroundColor: '#4A6118', backgroundPosition: 'top'}} className='Section'>
                <div style={{width: 512, height: '100%', maxWidth: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 50}}>
                    <h2 style={{color: 'white', width: '100%'}} className='Title'>{trip?.title}</h2>
                    <div style={{width: '400px', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)',backgroundColor: 'rgba(255,255,255,0.3)', marginTop: 50, maxWidth: 'calc(100% - 40px)', padding: '0px 20px',boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", display: 'flex', flexDirection: 'column'}}>
                        <p style={{fontSize: 'calc(1vw + 30px)', lineHeight: 'calc(1vw + 30px)', fontFamily: 'Nevduplenysh-Regular', textAlign: 'center', marginTop: 10}}>{new Date(trip.start_date).getDate() + ' ' + new Date(trip.start_date).toLocaleString('ru-RU', { month: 'short'}) + ' - ' + new Date(trip.end_date).getDate() + ' ' + new Date(trip.end_date).toLocaleString('ru-RU', { month: 'short'})}</p>
                        <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Сложность: </span><span>{trip?.difficulty}</span></p>
                        <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Категория: </span><span>{trip?.type + ', ' + trip?.max_count} человек</span></p>
                        <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Осталось: </span><span>{trip?.max_count - trip?.count} билетов</span></p>
                        <p style={{fontSize: 'calc(1vw + 30px)', lineHeight: 'calc(1vw + 30px)', fontFamily: 'Nevduplenysh-Regular', marginTop: 20, textAlign: 'right'}}>СТОИМОСТЬ {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(trip.price)}</p>
                        <a href={`${trip?.link}`} style={{color: 'white', fontSize: 'calc(0.7vw + 20px)', lineHeight: 'calc(0.7vw + 20px)', padding: '10px 0px', width: 'calc(100% + 40px)', textAlign: 'center', backgroundColor: 'black', marginLeft: -20, fontFamily: 'Nevduplenysh-Regular', marginTop: 20}}>ПЕРЕЙТИ НА СТРАНИЦУ ОБЪЕКТА</a>
                        <a onClick={() => book(1, trip?.id, trip?.max_count - trip?.count)} style={{color: 'white', fontSize: 'calc(0.7vw + 20px)', lineHeight: 'calc(0.7vw + 20px)', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: 'calc(100% + 40px)', textAlign: 'center', backgroundColor: '#4A6118', marginLeft: -20, cursor: 'pointer'}}>ЗАБРОНИРОВАТЬ</a>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor: '#eaeaea', padding: '75px 0px', flexDirection: 'row', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 75, width: '100%'}}>
                <h2 style={{color: '#4A6118', width: 'calc(100% - 40px)', textAlign: 'left'}} className='Title'>{trip.title}</h2>
                <p style={{ color: 'black', textAlign: 'left', width: 'calc(100% - 40px)' }} className='Description'>
                {trip.description.split('\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                        <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
                    </React.Fragment>
                ))}
                </p>
            </div>
            <div style={{backgroundColor: '#eaeaea', padding: '75px 0px', flexDirection: 'row', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 75, width: '100%'}}>
                <h2 style={{color: '#4A6118', width: 'calc(100% - 40px)', textAlign: 'left'}} className='Title'>Расписание по дням</h2>
                <div style={{width: 'calc(100% - 40px)', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 20}}>
                {
                    trip?.schelude.split('#').map((description, index) => {
                        return(
                        <div style={{width: '400px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                            <p style={{color: 'black', textAlign: 'left', maxWidth: '90%', width: '400px', margin: '10px 0px', fontSize: '60px', fontFamily: 'Nevduplenysh-Regular',}}>{index + 1} ДЕНЬ</p>
                            <p style={{color: 'gray', textAlign: 'left', width: '400px', maxWidth: '90%', marginBottom: '10px'}}>{description}</p>
                        </div>
                        )
                    })
                }
                </div>
            </div>
            <div style={{width: "100%", margin: "0 auto", padding: '50px 0px', backgroundColor: '#eaeaea'}}>
                <TeamSlider slides={team.filter((member) => trip.team.split('#').includes(member.id))} title="Команда" />
            </div>
        </div>
        :
        null
    )
}

export default TripPlanningComponent