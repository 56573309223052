import React, { useState, useEffect } from "react";
import TeamSlider from "../Components/TeamSlider";
import { useNavigate } from "react-router-dom";

function TripPlanning({user, setUser}){
    const [team, setTeam] = useState([])
    const [trips, setTrips] = useState([])
    const navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            try {
                const tripsResponse = await fetch('https://adm.trevatour.ru/api/website/gettrips.php');
                const tripsResponseData = await tripsResponse.json();
                const tripsWithData = tripsResponseData.map(item => ({
                        ...item,
                        quantity: 1
                }));
                setTrips(tripsWithData);
                const teamResponse = await fetch('https://adm.trevatour.ru/api/website/getteam.php');
                const teamResponseData = await teamResponse.json();
                setTeam(teamResponseData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
      
        fetchData();
    }, [])

    const increaseQuantity = (index) => {
        const updatedTrips = [...trips];
        if (updatedTrips[index].quantity < updatedTrips[index].max_count - updatedTrips[index].count) {
            updatedTrips[index].quantity++;
            setTrips(updatedTrips);
        }
    };

    const decreaseQuantity = (index) => {
        const updatedTrips = [...trips];
        if (updatedTrips[index].quantity > 1) {
            updatedTrips[index].quantity--;
            setTrips(updatedTrips);
        }
    };

    const book = async (quantity, id, max_count) => {
        if (user.email && user.hash) {
            try {
                const formData = new FormData();
                formData.append('email', user?.email);
                formData.append('hash', user?.hash);
                formData.append('quantity', quantity);
                formData.append('id', id);
                formData.append('max_count', max_count);
    
                const response = await fetch('https://adm.trevatour.ru/api/website/changetriptocart.php', {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    alert('Oшибка при добавлении товара в карзину!');
                }
    
                const responseData = await response.json();
    
                if (!responseData || responseData.error) {
                    alert('Oшибка при добавлении товара в карзину!');
                }else{
                    var updated_user = {...user}
                    updated_user.trips_cart = responseData?.rental || '[]'
                    setUser(updated_user)
                    navigate('/Cart')
                }
            } catch (error) {
                alert(error.message);
            }
        } else {
            navigate('/SignIn');
        }
    };

    return(
        <div>
            <div style={{backgroundImage: `url(${require('../Images/Main4.png')}`, backgroundColor: '#4A6118', backgroundPosition: 'top'}} className='Section'>
                <div className='Padding1'>
                    
                </div>
                <div style={{width: 700, height: '100%', maxWidth: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 50}}>
                    <h2 style={{color: 'white', textAlign: 'left'}} className='Title'>В путешествие<br/>по Дагестану...</h2>
                    <h2 style={{color: 'white', textAlign: 'right', fontSize: 'calc(4vw + 40px)', lineHeight: 'calc(4vw + 20px)'}} className='Title'>С группой&nbsp;&nbsp;&nbsp;<br/>ТУРистов!</h2>
                    <p style={{fontSize: '20px', lineHeight: '30px', marginTop: '30px', textAlign: 'right'}} className='Description'>Для тех, кто предпочитает путешествовать в компании. Подберите подходящую вам поездку и отправляйтесь в Путь.</p>
                </div>
            </div>
            <div style={{backgroundColor: '#d6d2c9', flexDirection: 'row', rowGap: '80px', alignItems: 'flex-start', justifyContent: 'space-evenly', flexWrap: 'wrap', padding: '60px 0px'}} className="Section">
            {trips.map((trip, index) => {
                return(
                <div style={{width: '500px', maxWidth: 'calc(100% - 40px)', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                    <div style={{backgroundImage: `url(${trip.image_url}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <h2 style={{color: 'white'}} className='Title'>{new Date(trip.start_date).getDate() + ' ' + new Date(trip.start_date).toLocaleString('ru-RU', { month: 'short'}) + ' - ' + new Date(trip.end_date).getDate() + ' ' + new Date(trip.end_date).toLocaleString('ru-RU', { month: 'short'})}</h2>
                    </div>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', maxWidth: '90%', width: '400px', margin: '10px 0px', fontWeight: '700', fontSize: 24}}>{trip.title}</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', width: '400px', maxWidth: '90%', marginBottom: '10px'}}>осталось мест: {trip.max_count - trip.count}</p>
                    <p style={{color: 'gray', backgroundColor: 'white', textAlign: 'left', width: '400px', maxWidth: '90%', marginBottom: '10px'}}>{trip.short_description}</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'center', width: '400px', maxWidth: '90%'}}>Стоимость</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'center', fontSize: '30px', lineHeight: '50px', width: '400px', maxWidth: '90%', fontWeight: '700'}}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(trip.quantity * trip.price)}</p>
                    <div style={{width: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <button onClick={() => decreaseQuantity(index)} style={{height: '50px', width: '50px', fontSize: '30px', lineHeight: '0px', color: 'white', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer'}}>-</button>
                        <p style={{fontSize: '20px', fontWeight: '600', width: '50px', textAlign: 'center'}}>{trip.quantity}</p>
                        <button onClick={() => increaseQuantity(index)} style={{height: '50px', width: '50px', fontSize: '30px', lineHeight: '0px', color: 'white', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer'}}>+</button>
                    </div>
                    <a href={`TripPlanning/${trip?.id}`} style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <a onClick={() => book(trip?.quantity, trip?.id, trip.max_count - trip.count)} style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: '#4A6118', cursor: 'pointer'}}>ЗАБРОНИРОВАТЬ</a>
                </div>
                )
            })
            }
            </div>
            <div style={{width: "100%", margin: "0 auto", padding: '50px 0px', backgroundColor: '#eaeaea'}} className="Section">
                <TeamSlider slides={team} title="Наша команда" />
            </div>
        </div>
    )
}

export default TripPlanning