import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Rental({user, setUser}) {
    const [rental, setRental] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            try {
              const response = await fetch('https://adm.trevatour.ru/api/website/getrental.php');
              const responseData = await response.json();
              const rentalWithData = responseData.map(item => ({
                ...item,
                quantity: 1,
                showDescription: false
            }));

            setRental(rentalWithData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
      
        fetchData();
    }, [])

    const increaseQuantity = (index) => {
        const updatedRental = [...rental];
        if (updatedRental[index].quantity < updatedRental[index].max_count) {
            updatedRental[index].quantity++;
            setRental(updatedRental);
        }
    };

    const decreaseQuantity = (index) => {
        const updatedRental = [...rental];
        if (updatedRental[index].quantity > 1) {
            updatedRental[index].quantity--;
            setRental(updatedRental);
        }
    };
    
    const showDescription = (index, show) => {
        const updatedRental = [...rental];
        updatedRental[index].showDescription = show;
        setRental(updatedRental);
    }

    const rent = async (quantity, id, max_count) => {
        if (user.email && user.hash) {
            try {
                const formData = new FormData();
                formData.append('email', user?.email);
                formData.append('hash', user?.hash);
                formData.append('quantity', quantity);
                formData.append('id', id);
                formData.append('max_count', max_count);
    
                const response = await fetch('https://adm.trevatour.ru/api/website/changerentaltocart.php', {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    alert('Oшибка при добавлении товара в карзину!');
                }
    
                const responseData = await response.json();
    
                if (!responseData || responseData.error) {
                    alert('Oшибка при добавлении товара в карзину!');
                }else{
                    var updated_user = {...user}
                    updated_user.rental_cart = responseData?.rental || '[]'
                    setUser(updated_user)
                    navigate('/Cart')
                }
            } catch (error) {
                alert(error.message);
            }
        } else {
            navigate('/SignIn');
        }
    };
    
    return(
        <div>
            <div style={{backgroundImage: `url(${require('../Images/Rental.png')}`, backgroundPosition: 'center', backgroundColor: '#d6d2c9'}} className='Section'>
                <div className='Padding1'>
                    
                </div>
                <div style={{width: 512, height: '100%', maxWidth: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 50}}>
                    <h2 style={{color: 'white', textAlign: 'right'}} className='Title'>Прокат снаряжения</h2>
                    <p style={{marginTop: '30px', textAlign: 'right', lineHeight: '30px'}} className='Description'>Если у Вас нет необходимого снаряжения, мы готовы его предоставить! Забронируйте полноценные паки для путешествия или частичные позиции.</p>
                </div>
            </div>
            <div style={{backgroundColor: '#d6d2c9', flexDirection: 'row', gap: '30px', alignItems: 'flex-start', justifyContent: 'space-evenly', flexWrap: 'wrap', padding: '60px 0px'}} className='Section'>
                {
                    rental.map((item, index) => {
                        return(
                        <div key={item.id} style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                            <div style={{backgroundImage: `url(${item?.image_url})`, backgroundPosition: 'center', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300}} className='Section'></div>
                            <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', maxWidth: '90%', width: '450px', margin: '10px 0px', fontWeight: '700'}}>{item?.title}</p>
                            {item?.showDescription &&
                            <p style={{color: 'gray', backgroundColor: 'white', textAlign: 'left', marginTop: '0px', lineHeight: '30px', width: '450px', maxWidth: '90%'}}>
                            {
                                item?.description.split('\n').map((paragraph, index) => (
                                <React.Fragment key={index}>
                                {paragraph}
                                <br />
                                </React.Fragment>
                                ))
                            }
                            </p>
                            }
                            <p style={{color: 'black', backgroundColor: 'white', textAlign: 'center', width: '400px', maxWidth: '90%',}}>Стоимость</p>
                            <p style={{color: 'black', backgroundColor: 'white', textAlign: 'center', fontSize: '30px', lineHeight: '50px', width: '400px', maxWidth: '90%', fontWeight: '700'}}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.quantity * item.price)}</p>
                            <div style={{width: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <button onClick={() => decreaseQuantity(index)} style={{height: '50px', width: '50px', fontSize: '30px', lineHeight: '0px', color: 'white', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer'}}>-</button>
                                <p style={{fontSize: '20px', fontWeight: '600', width: '20px'}}>{item.quantity}</p>
                                <button onClick={() => increaseQuantity(index)} style={{height: '50px', width: '50px', fontSize: '30px', lineHeight: '0px', color: 'white', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer'}}>+</button>
                            </div>
                            <a onClick={() => showDescription(index, !item?.showDescription)} style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black', cursor: 'pointer'}}>ПОДРОБНЕЕ</a>
                            <a onClick={() => rent(item.quantity, item.id, item.max_count)} style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: '#4A6118', cursor: 'pointer'}}>В КОРЗИНУ</a>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Rental