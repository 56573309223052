import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SignUp({user, setUser}){
    const [avatar, setAvatar] = useState(null);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [placeOfResidence, setPlaceOfResidence] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [error, setError] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const navigate = useNavigate();

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setAvatar(reader.result)
            setSelectedFile(e.target.files[0])
        };
        reader.readAsDataURL(file);
        }
    };

    const handlePictureUpload = async () => {
        try {
            if(fullName.length > 0 && email.length > 0 && phone.length > 0 && placeOfResidence.length > 0 && password.length > 0 && password.length > 0){
                if(password == passwordRepeat){
                    const formData = new FormData();
                    formData.append('image', selectedFile);
                    var pictureUrl = 'https://adm.trevatour.ru/media/avatar.jpg'
              
                    const response = await fetch('https://adm.trevatour.ru/api/website/uploadimage.php', {
                      method: 'POST',
                      body: formData,
                    });
                    
                    const responseData = await response.json();
                    if(responseData?.image_url){
                      pictureUrl =  responseData?.image_url
                    }
          
                    const userFormData = new FormData();
                    userFormData.append('full_name', fullName);
                    userFormData.append('email', email);
                    userFormData.append('place_of_residence', placeOfResidence);
                    userFormData.append('password', password);
                    userFormData.append('phone', phone);
                    userFormData.append('picture_url', pictureUrl);
          
                    const adduserResponse = await fetch('https://adm.trevatour.ru/api/website/adduser.php', {
                      method: 'POST',
                      body: userFormData,
                    });
          
                    const adduserResponseData = await adduserResponse.json();
                    if(adduserResponseData?.success){
                      localStorage.setItem('email', email)
                      localStorage.setItem('hash', adduserResponseData?.user?.hash)
                      setUser(adduserResponseData?.user)

                      navigate('/Account')
                    }else if(adduserResponseData?.error){
                      setError(adduserResponseData?.error)
                    }else{
                      setError('Ошибка')
                    }
                }else{
                    setError('Пароли не совпадают')
                }
            }else{
                setError('Все поля обязательны к заполнению!')
            }
        } catch (error) {
          setError(error);
        }
    };

    return(
        <div>
            <div style={{backgroundImage: `url(${require('../Images/Main1.jpg')}`, backgroundPosition: 'top', backgroundColor: '#d6d2c9', padding: '0px'}} className='Section'>
                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '150px 0px', alignItems: 'center', WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)'}}>
                    <h2 style={{color: 'white', fontFamily: 'Nevduplenysh-Regular', fontSize: 'calc(3vw + 40px)', lineHeight: 'calc(3vw + 40px)'}} className='Title'>Регистрация</h2>
                    <form style={{width: '400px', display: 'flex', flexDirection: 'column', marginTop: '50px', rowGap: '40px', maxWidth: '100%'}}>
                        <label htmlFor="files" style={{cursor: 'pointer', alignSelf: 'center'}}>
                            <div style={{backgroundImage: `url(${avatar || 'https://adm.trevatour.ru/media/avatar.jpg'}`, width: '100px', height: '100px', borderRadius: '50px', backgroundRepeat: 'no-repeat', backgroundPosition: 'top', backgroundSize: 'cover'}}></div>
                        </label>
                        <input name='image' onChange={handleAvatarChange} accept='images/*' id="files" style={{display: 'none'}} type="file"/>                        
                        <input name='full_name' onChange={(event) => setFullName(event.target.value)} value={fullName} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="ФИО" type="text" />
                        <input name='email' onChange={(event) => setEmail(event.target.value)} value={email} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Email" type="email" />
                        <input name='phone' onChange={(event) => setPhone(event.target.value)} value={phone} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Номер Телефона" type="text" />
                        <input name='place_of_residence' onChange={(event) => setPlaceOfResidence(event.target.value)} value={placeOfResidence} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Место проживания" type="text" />
                        <input name='password' onChange={(event) => setPassword(event.target.value)} value={password} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Пароль" type="password" />
                        <input name='password_repeat' onChange={(event) => setPasswordRepeat(event.target.value)} value={passwordRepeat} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Повторите пароль" type="password" />
                        {error && <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p>}
                        <a onClick={handlePictureUpload} style={{color: 'black', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', maxWidth: '100%', width: '400px', textAlign: 'center', backgroundColor: 'white', cursor: 'pointer'}}>СОЗДАТЬ АККАУНТ</a>
                        <a href='/SignIn' style={{color: 'white'}}>Войти в аккаунт</a>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignUp