import React from "react";

function Footer(){
    return(
        <div style={{width: '100%', backgroundColor: 'black', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '50px 0px', justifyContent: 'center'}}>
            <div style={{minWidth: '50%', maxWidth: '70%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: 20}}>
                <img alt="treva" style={{width: '100px'}} src={require('../Images/treva.png')} />
                <p style={{color: 'white', fontWeight: 600, textAlign: 'center'}}>(C)opyright 2024.</p>
            </div>
            <div style={{minWidth: '50%', maxWidth: '90%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', marginTop: 20, gap: 20}}>
                <img alt="fond" style={{width: '100px'}} src={require('../Images/fond_logo.png')} />
                <p style={{color: 'white', fontWeight: 600, textAlign: 'center'}}>Проект поддержан Фондом содействия инновациям в рамках программы «Студенческий стартап» федерального проекта
                «Платформа университетского технологического предпринимательства»*</p>
            </div>
        </div>
    )
}

export default Footer