import React, { useState, useEffect } from "react";
import GallerySlider from "../Components/GallerySlider";
import { useParams } from "react-router-dom";

function BlogComponent(){
    const [data, setData] = useState(null)
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
          try {
            const response = await fetch('https://adm.trevatour.ru/api/website/getblogcomponent.php?id=' + id);
            const responseData = await response.json();
            setData(responseData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }, []);

    return(
        data != null ?
        <div>
            <div style={{backgroundImage: `url(${data.image_urls.split('#')[0]})`, backgroundPosition: 'center', backgroundColor: '#d6d2c9'}} className='Section'>
                <h2 style={{color: 'white'}} className='Title'>{data?.title}</h2>
            </div>
            <div style={{backgroundColor: '#eaeaea', padding: '75px 0px', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap'}} className='Section'>
                <h2 style={{color: '#4A6118', width: '100%', width: '90%', textAlign: 'left'}} className='Title'>{data?.title}</h2>
                <p style={{ color: 'black', marginTop: '50px', lineHeight: '30px', textAlign: 'left', width: '90%' }} className='Description'>
                {data?.description.split('\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                    {paragraph}
                    <br />
                    </React.Fragment>
                ))}
                </p>
            </div>
            <div style={{width: "100%", margin: "0 auto", padding: '50px 0px', backgroundColor: '#eaeaea'}}>
                <GallerySlider slides={data?.image_urls.split('#')} />
            </div>
        </div>
        :
        null
    )
}

export default BlogComponent