import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Cart({user, setUser}){
    const [rental, setRental] = useState([])
    const [trips, setTrips] = useState([])
    const navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            window.scrollTo(0,0)
            const formData = new FormData();
            formData.append('email', user?.email);
            formData.append('hash', user?.hash);
            if (user.email && user.hash) {
                try {
                    const rentalResponse = await fetch('https://adm.trevatour.ru/api/website/getrentalcart.php', {
                        method: 'POST',
                        body: formData,
                    });
        
                    if (!rentalResponse.ok) {
                        alert('Error fetching rental data!');
                    }
                    const rentalResponseData = await rentalResponse.json();
                    if (rentalResponseData && !rentalResponseData.error && !rentalResponseData.message) {
                        setRental(rentalResponseData);
                    }else{
                        console.log('Error: ' + rentalResponseData.error);
                    }
                    

                    const tripResponse = await fetch('https://adm.trevatour.ru/api/website/gettripscart.php', {
                        method: 'POST',
                        body: formData,
                    });
        
                    if (!tripResponse.ok) {
                        alert('Error fetching rental data!');
                    }
                    const tripResponseData = await tripResponse.json();
                    if (tripResponseData && !tripResponseData.error && !tripResponseData.message) {
                        setTrips(tripResponseData);
                    }else{
                        console.log('Error: ' + tripResponseData.error);
                    }
                    

                } catch (error) {
                    console.error('Error fetching data:', error);
                    alert('Error fetching rental data!');
                }
            } else {
                navigate('/SignIn');
            }
        };
      
        fetchData();
    }, [])

    const changeRentalQuantity = async(quantity, id, max_count) => {
        if (user.email && user.hash) {
            try {
                const formData = new FormData();
                formData.append('email', user?.email);
                formData.append('hash', user?.hash);
                formData.append('quantity', quantity);
                formData.append('id', id);
                formData.append('max_count', max_count);
    
                const response = await fetch('https://adm.trevatour.ru/api/website/changerentaltocart.php', {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    alert('Oшибка при добавлении товара в карзину!');
                }
    
                const responseData = await response.json();
    
                if (!responseData) {
                    alert('Oшибка при добавлении товара в карзину!');
                }
                
                setRental(responseData?.rental_items || [])
                var updated_user = {...user}
                updated_user.rental_cart = responseData?.rental || '[]'
                setUser(updated_user)
            } catch (error) {
                alert(error.message);
            }
        } else {
            navigate('/SignIn');
        }
    };

    const changeTripQuantity = async(quantity, id, max_count) => {
        if (user.email && user.hash) {
            try {
                const formData = new FormData();
                formData.append('email', user?.email);
                formData.append('hash', user?.hash);
                formData.append('quantity', quantity);
                formData.append('id', id);
                formData.append('max_count', max_count);
    
                const response = await fetch('https://adm.trevatour.ru/api/website/changetriptocart.php', {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    alert('Oшибка при добавлении товара в карзину!');
                }
    
                const responseData = await response.json();
    
                if (!responseData) {
                    alert('Oшибка при добавлении товара в карзину!');
                }

                setTrips(responseData?.rental_items || [])
                var updated_user = {...user}
                updated_user.trips_cart = responseData?.rental || '[]'
                setUser(updated_user)
            } catch (error) {
                alert(error.message);
            }
        } else {
            navigate('/SignIn');
        }
    };

    return(
        <div>
            <div style={{backgroundColor: '#D6D2C9', rowGap: 'calc(1vw + 20px)', padding: 'calc(3vw + 50px) 0px', flexDirection: 'column'}} className='Section'>
                <h2 style={{color: '#4A6118', width: 'calc(80% + 40px)', textAlign: 'left'}} className='Title'>Корзина</h2>
                {
                    trips.map((item, index) => {
                        return(
                            <div style={{width: 'calc(80% + 40px)', backgroundImage: `url(${item?.image_url})`, display: 'flex', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)', padding: 20, backgroundColor: 'rgba(29, 37, 44, 0.4)'}}>
                                    <h2 style={{color: 'white', fontSize: 'calc(4vw + 20px)', lineHeight: 'calc(4vw + 20px)', textAlign: 'left', width: '100%', fontFamily: 'Nevduplenysh-Regular', fontWeight: '500'}}>{item.title + ' ' + new Date(item.start_date).getDate() + ' ' + new Date(item.start_date).toLocaleString('ru-RU', { month: 'short'}) + ' - ' + new Date(item.end_date).getDate() + ' ' + new Date(item.end_date).toLocaleString('ru-RU', { month: 'short'})}</h2>
                                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'white', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Сложность: </span><span>{item?.difficulty}</span></p>
                                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'white', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Категория: </span><span>{item?.type + ', ' + item?.max_count} человек</span></p>
                                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'white', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Осталось: </span><span>{item?.max_count - item?.count} билетов</span></p>
                                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 20}}>
                                        <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                                            <button onClick={() => changeTripQuantity(-1 * item.quantity, item.id, item.max_count - item?.count)} style={{height: 'calc(2vw + 20px)', width: 'calc(2vw + 20px)', backgroundColor: 'darkred', borderStyle: 'none', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                <svg fill="white" width='calc(1vw + 10px)' height='calc(1vw + 10px)' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z"/></svg>
                                            </button>
                                            <button onClick={() => changeTripQuantity(-1, item.id, item.max_count - item?.count)} style={{height: 'calc(2vw + 20px)', width: 'calc(2vw + 20px)', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                <svg width='calc(1vw + 10px)' height='calc(1vw + 10px)' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 12L18 12" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                            <p style={{fontSize: 'calc(0.5vw + 10px)', fontWeight: '600', width: 'calc(2vw + 20px)', backgroundColor: 'white', textAlign: 'center', height: 'calc(2vw + 20px)', lineHeight: 'calc(2vw + 20px)'}}>{item.quantity}</p>
                                            <button onClick={() => changeTripQuantity(1, item.id, item.max_count - item?.count)} style={{height: 'calc(2vw + 20px)', width: 'calc(2vw + 20px)', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                <svg  width='calc(1vw + 10px)' height='calc(1vw + 10px)' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 12H18M12 6V18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <h2 style={{color: 'white', fontSize: 'calc(0.5vw + 20px)', lineHeight: 'calc(2vw + 20px)', textAlign: 'left', fontWeight: '500'}}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.quantity * item.price)}</h2>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    rental.map((item, index) => {
                        return(
                            <div style={{width: '80%', backgroundColor: '#1D252C', display: 'flex', flexWrap: 'wrap',boxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", padding: 20}}>
                                <h2 style={{color: 'white', fontSize: 'calc(4vw + 20px)', lineHeight: 'calc(4vw + 20px)', textAlign: 'left', width: '100%', fontFamily: 'Nevduplenysh-Regular', fontWeight: '500'}}>{item.title}</h2>
                                <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'white'}}>
                                {
                                    item?.description
                                }
                                </p>
                                <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 20}}>
                                <div style={{alignItems: 'center', display: 'flex', flexDirection: 'row'}}>
                                            <button onClick={() => changeRentalQuantity(-1 * item.quantity, item.id, item.max_count)} style={{height: 'calc(2vw + 20px)', width: 'calc(2vw + 20px)', backgroundColor: 'darkred', borderStyle: 'none', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                <svg fill="white" width='calc(1vw + 10px)' height='calc(1vw + 10px)' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z"/></svg>
                                            </button>
                                            <button onClick={() => changeRentalQuantity(-1, item.id, item.max_count)} style={{height: 'calc(2vw + 20px)', width: 'calc(2vw + 20px)', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                <svg width='calc(1vw + 10px)' height='calc(1vw + 10px)' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 12L18 12" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                            <p style={{fontSize: 'calc(0.5vw + 10px)', fontWeight: '600', width: 'calc(2vw + 20px)', backgroundColor: 'white', textAlign: 'center', height: 'calc(2vw + 20px)', lineHeight: 'calc(2vw + 20px)'}}>{item.quantity}</p>
                                            <button onClick={() => changeRentalQuantity(1, item.id, item.max_count)} style={{height: 'calc(2vw + 20px)', width: 'calc(2vw + 20px)', backgroundColor: '#4A6118', borderStyle: 'none', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                <svg  width='calc(1vw + 10px)' height='calc(1vw + 10px)' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 12H18M12 6V18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <h2 style={{color: 'white', fontSize: 'calc(0.5vw + 20px)', lineHeight: 'calc(2vw + 20px)', textAlign: 'left', fontWeight: '500'}}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.quantity * item.price)}</h2>
                                </div>
                            </div>
                        )
                    })
                }
                <hr style={{width: '90%'}}/>
                <div style={{width: '80%', backgroundColor: '#eaeaea', display: 'flex', flexWrap: 'wrap',boxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", padding: 20}}>
                    <h2 style={{color: 'black', fontSize: 'calc(4vw + 20px)', lineHeight: 'calc(4vw + 20px)', textAlign: 'center', width: '100%', fontFamily: 'Nevduplenysh-Regular', fontWeight: '500'}}>ИТОГО</h2>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>ФИО: </span><span>{user?.full_name}</span></p>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Эл. адрес: </span><span>{user?.email}</span></p>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Номер телефона: </span><span>{user?.phone}</span></p>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Место проживания: </span><span>{user?.place_of_residence}</span></p>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', lineHeight: 'calc(0.5vw + 22px)', width: '100%', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}><span style={{fontWeight: '600'}}>Сумма: </span><h2 style={{color: 'black', fontSize: 'calc(0.5vw + 15px)', lineHeigh: 'calc(0.5vw + 15px)', textAlign: 'left', fontWeight: '500'}}>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(rental.reduce((acc, item) => {return acc + item.quantity * item.price}, 0) + trips.reduce((acc, item) => {return acc + item.quantity * item.price}, 0))}</h2></p>
                    <button style={{height: '50px', width: '100%', marginTop: '20px', fontSize: '30px', lineHeight: '0px', color: 'white', backgroundColor: rental.length + trips.length > 0 ? '#4A6118' : 'gray', borderStyle: 'none', cursor: 'pointer', fontFamily: 'Nevduplenysh-Regular', lineHeight: '55px'}}>ПЕРЕЙТИ К ОПЛАТЕ</button>
                </div>
            </div>
        </div>
    )
}

export default Cart