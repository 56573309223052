import React, {useState} from "react";
import '../Styles/Header.css'
import { Link, useLocation } from 'react-router-dom';

function Header({user}){
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false)
    const isMobile = () => {
        return window.innerWidth <= 720;
    };
    if (isMobile()) {

    return(
        <div style={{backgroundColor: 'rgba(0,0,0,0.1)', backdropFilter: 'blur(30px)', WebkitBackdropFilter: 'blur(30px)', position: 'fixed', width: '100%', zIndex: 999}}>
            <div className="MobileNavBar">
                <div className="MobileNavBarItem">
                    <Link onClick={() => setMenuOpen(false)} to="/"><img alt="Treva" className="Logo" src={require("../Images/treva.png")} /></Link>
                </div>
                <div style={{display: 'flex'}}>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} to="/Cart">
                            <img alt="Cart" className="Logo" src={require("../Images/cart.png")} />
                        </Link>
                        {JSON.parse(user?.rental_cart || "[]").length + JSON.parse(user?.trips_cart || "[]").length > 0 && (
                            <div style={{position: 'absolute',top: 0, right: 100,backgroundColor: 'darkred',borderRadius: '50%',width: 25,height: 25,display: 'flex',justifyContent: 'center',alignItems: 'center',zIndex: 1, border: '2px solid white'}}>
                                <p style={{ color: 'white', fontSize: 16, fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", marginBottom: 3 , fontWeight: '500' }}>
                                    {JSON.parse(user?.rental_cart).reduce((acc, item) => {return acc + item?.quantity}, 0) + JSON.parse(user?.trips_cart).reduce((acc, item) => {return acc + item?.quantity}, 0)}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} to="/Account"><img alt="Account" className="Logo" style={user?.picture_url ? {width: '30px', height: '30px', border: '2px solid white', alignSelf: 'center'} : {}} src={user?.picture_url || require("../Images/account.png")} /></Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <a onClick={() => setMenuOpen(!menuOpen)}>
                            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_429_11066)">
                                <path d="M3 6.00092H21M3 12.0009H21M3 18.0009H21" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_429_11066">
                                <rect width="24" height="24" fill="white" transform="translate(0 0.000915527)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            {
                menuOpen && 
                <div style={{width: '100%', height: '100vh', padding: '20px 0px', rowGap: 10, display: 'flex', flexDirection: 'column'}}>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/WhereToGo">КУДА СЪЕЗДИТЬ?</Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/TripPlanning">ПЛАНИРОВАНИЕ ПОЕЗДКИ</Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/Blog">БЛОГ</Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/#Reviews">ОТЗЫВЫ</Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/Rental">ПРОКАТ СНАРЯЖЕНИЯ</Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/#FAQ">FAQ</Link>
                    </div>
                    <div className="MobileNavBarItem">
                        <Link onClick={() => setMenuOpen(false)} style={{width: '100%', textAlign: 'center'}} to="/#Contacts">КОНТАКТЫ</Link>
                    </div>
                </div>
            }
        </div>
        )}else{
            if(menuOpen){
                setMenuOpen(false)
            }
            return(
                <div className="NavBar">
                    <div style={{marginLeft: '10px'}} className={`NavBarItem ${location.pathname === "/" ? "active" : ""}`}>
                        <Link to="/"><img alt="Treva" className="Logo" src={require("../Images/treva.png")} /></Link>
                    </div>
                    <div style={{display: 'flex', gap: 40}}>
                        <div className={`NavBarItem ${location.pathname.startsWith("/WhereToGo") ? "active" : ""}`}>
                            <Link to="/WhereToGo">КУДА СЪЕЗДИТЬ?</Link>
                        </div>
                        <div className={`NavBarItem ${location.pathname.startsWith("/TripPlanning") ? "active" : ""}`}>
                            <Link to="/TripPlanning">ПЛАНИРОВАНИЕ ПОЕЗДКИ</Link>
                        </div>
                        <div className={`NavBarItem ${location.pathname.startsWith("/Blog") ? "active" : ""}`}>
                            <Link to="/Blog">БЛОГ</Link>
                        </div>
                        <div className={`NavBarItem ${location.hash == '#Reviews' ? "active" : ""}`}>
                            <Link to="/#Reviews">ОТЗЫВЫ</Link>
                        </div>
                        <div className={`NavBarItem ${location.pathname.startsWith("/Rental") ? "active" : ""}`}>
                            <Link to="/Rental">ПРОКАТ СНАРЯЖЕНИЯ</Link>
                        </div>
                        <div className={`NavBarItem ${location.hash == '#FAQ' ? "active" : ""}`}>
                            <Link to="/#FAQ">FAQ</Link>
                        </div>
                        <div className={`NavBarItem ${location.hash == '#Contacts' ? "active" : ""}`}>
                            <Link to="/#Contacts">КОНТАКТЫ</Link>
                        </div>
                        <div className={`NavBarItem ${location.pathname.startsWith("/Cart") ? "active" : ""}`} style={{ position: 'relative' }}>
                            <Link to="/Cart">
                                <img alt="Cart" className="Logo" src={require("../Images/cart.png")} />
                            </Link>
                            {JSON.parse(user?.rental_cart || "[]").length + JSON.parse(user?.trips_cart || "[]").length > 0 && (
                                <div style={{position: 'absolute',top: 0, right: 0,backgroundColor: 'darkred',borderRadius: '50%',width: 25,height: 25,display: 'flex',justifyContent: 'center',alignItems: 'center',zIndex: 1, border: '2px solid white'}}>
                                    <p style={{ color: 'white', fontSize: 16, fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", marginBottom: 3 , fontWeight: '500' }}>
                                        {JSON.parse(user?.rental_cart).reduce((acc, item) => {return acc + item?.quantity}, 0) + JSON.parse(user?.trips_cart).reduce((acc, item) => {return acc + item?.quantity}, 0)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{marginRight: '10px'}} className={`NavBarItem ${location.pathname.includes("Account") || location.pathname.includes("Sign") ? "active" : ""}`}>
                        <Link to="/Account"><img alt="Account" className="Logo" style={user?.picture_url ? {width: '30px', height: '30px', border: '2px solid white', alignSelf: 'center'} : {}} src={user?.picture_url || require("../Images/account.png")} /></Link>
                    </div>
                </div>
            )
        }
    
}

export default Header