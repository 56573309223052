import React, { useState, useEffect } from "react";

function WhereToGo(){
    const [places, setPlaces] = useState([]);
    const [categories, setCategories] = useState(['все'])
    const [selectedCategory, setSelectedCategory] = useState('все')
  
    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            try {
            const response = await fetch('https://adm.trevatour.ru/api/website/getwheretogolist.php');
            const responseData = await response.json();
            const updatedCategories = [...categories]
            responseData.map((place, index) => {
                place.category.split('#').map((category, index) => {
                    if(!updatedCategories.includes(category)){
                        updatedCategories.push(category)
                    }
                })
            })
            setCategories(updatedCategories)
            setPlaces(responseData);
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    return(
        <div>
            <div style={{backgroundImage: `url(${require('../Images/WhereToGo.png')}`, alignItems: 'flex-start'}} className="Section">
                <div style={{height: '100%', maxWidth: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 50}}>
                    <h2 style={{color: 'white', marginTop: 100}} className='Title'>Куда<br/>съездить?</h2>
                    <p className='Description_Bold'>Наша библиотека точек интереса.</p>
                    <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', gap: 20, alignItems: 'center', justifyContent: 'center'}}>
                    {
                        categories.map((category, index) => {
                            return(
                                <button key={category} onClick={() => setSelectedCategory(category)} style={{backgroundColor: selectedCategory == category ? '#4A6118' : 'transparent', border: '2px solid white', color: 'white', paddingLeft: '20px', paddingRight: '20px'}} className='MainButton'>{category}</button>
                            )
                        })
                    }       
                    </div>
                </div>
            </div>
            <div style={{backgroundColor: '#d6d2c9', padding: '75px 0px'}} className='Section'>
                <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', rowGap: 40, alignItems: 'center', justifyContent: 'space-evenly'}}>
                    {
                        places.map((place, index) => {
                            return(
                                place.category.includes(selectedCategory) || selectedCategory == 'все' ?
                                <div key={place.id} style={{backgroundImage: `url(${place.image_urls.split('#')[0]})`, width: 500, aspectRatio: 1, alignItems: 'center', justifyContent: 'center', maxWidth: '95%', display: 'flex', borderRadius: '15px', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} >
                                    <a href={`WhereToGo/${place.id}`} style={{color: 'white', textDecoration: 'none', fontSize: 'calc(3vw + 40px)', maxWidth: '95%'}} className='Title'>{place.title}</a>
                                </div>
                                :
                                null
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default WhereToGo