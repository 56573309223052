import React, { useState, useEffect } from "react";
import GallerySlider from "../Components/GallerySlider";
import { useParams } from "react-router-dom";

function WhereToGoComponent(){
    const [data, setData] = useState(null)
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
          try {
            const response = await fetch('https://adm.trevatour.ru/api/website/getwheretogocomponent.php?id=' + id);
            const responseData = await response.json();
            setData(responseData);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);


    return(
        data != null ?
        <div>
            <div style={{backgroundImage: `url(${data.image_urls.split('#')[0]})`, backgroundPosition: 'center', backgroundColor: '#d6d2c9'}} className='Section'>
                <h2 style={{color: 'white'}} className='Title'>{data.title}</h2>
            </div>
            <div style={{backgroundColor: '#eaeaea', padding: '75px 0px', flexDirection: 'row', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 75, width: '100%'}}>
                <h2 style={{color: '#4A6118', width: 'calc(100% - 40px)', textAlign: 'left'}} className='Title'>{data.title}</h2>
                <p style={{ color: 'black', textAlign: 'left', width: 'calc(100% - 40px)' }} className='Description'>
                {data.description.split('\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                        <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
                    </React.Fragment>
                ))}
                </p>
            </div>
            <div style={{width: "100%", margin: "0 auto", padding: '50px 0px', backgroundColor: '#eaeaea'}}>
                <GallerySlider slides={data.image_urls.split('#')} />
            </div>
            <div style={{backgroundImage: `url(${require('../Images/WhereToGo2.png')}`, backgroundColor: 'white', backgroundPosition: 'top'}} className='Section'>
                <div style={{width: 'calc(100% - 40px)', height: '100%', maxWidth: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', rowGap: 50}}>
                    <h2 style={{color: '#4A6118'}} className='Title'>А вы знаете что рядом есть...</h2>
                    <div style={{width: '80%', display: 'flex', flexWrap: 'wrap', gap: 40, alignItems: 'center', justifyContent: 'center', marginTop: '30px'}}>
                        {data.nearby.split('#').map((nearby, index) => {
                            return <div key={nearby} style={{padding: 'calc(1vw + 10px) calc(1vw + 20px)', fontSize: 'calc(1vw + 20px)', fontFamily: 'Nevduplenysh-Regular', backdropFilter: 'blur(20px)', WebkitBackdropFilter: 'blur(20px)', background: 'transparent', borderStyle: 'none', border: '2px solid black', borderRadius: '10px', color: 'black'}}>{nearby}</div>
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
        :
        null
    )
}

export default WhereToGoComponent