import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Account({user, setUser}){
    const navigate = useNavigate();

    useEffect(() => {
        
        const fetchData = async () => {
            if (user.email && user.hash) {
                window.scrollTo(0,0);
            }else {
                navigate('/SignIn');
            }
        };
        fetchData();
    }, []);

    const logOut = () => {
        localStorage.clear()
        setUser({})
        navigate('/SignIn')
    }

    return(
        user.email ? 
        <div>
            <div style={{backgroundColor: '#d6d2c9', backgroundImage: `url(${require('../Images/Main1.jpg')}`}} className='Section'>
                <div style={{width: '700px', maxWidth: '100%', backgroundColor: 'rgba(255,255,255,0.2)', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.1)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0, 0, 0,0.1)", backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(20px)'}}>
                    <div style={{backgroundImage: `url(${user?.picture_url}`, width: '150px', height: '150px', borderRadius: '75px', backgroundRepeat: 'no-repeat', backgroundPosition: 'top', backgroundSize: 'cover', margin: '50px 0px'}}></div>
                    <h2 style={{fontFamily: 'Nevduplenysh-Regular', fontSize: 'calc(3vw + 30px)', lineHeight: 'calc(3vw + 40px)'}} className='Title'>{user?.full_name}</h2>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', width: '600px', maxWidth: '95%',lineHeight: '26px', marginTop: '30px', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}} className='Description'><span style={{fontWeight: '600'}}>Эл. адрес: </span><span>{user?.email}</span></p>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', width: '600px', maxWidth: '95%',lineHeight: '26px', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}} className='Description'><span style={{fontWeight: '600'}}>Номер телефона: </span><span>{user?.phone}</span></p>
                    <p style={{fontSize: 'calc(0.5vw + 12px)', width: '600px', maxWidth: '95%',lineHeight: '26px', textAlign: 'left', color: 'black', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}} className='Description'><span style={{fontWeight: '600'}}>Место проживания: </span><span>{user?.place_of_residence}</span></p>
                    <a href="/EditAccount" style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black', cursor: 'pointer', marginTop: '30px'}}>Редактировать</a>
                    <a onClick={logOut} style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'darkred', cursor: 'pointer'}}>Выйти</a>
                </div>
            </div>
            {/* <div style={{backgroundColor: '#d6d2c9', flexDirection: 'row', gap: '30px', alignItems: 'flex-start', justifyContent: 'space-evenly', flexWrap: 'wrap', padding: '60px 0px'}} className='Section'>
                <h2 style={{color: 'black', width: '100%', fontSize: '80px', lineHeight: '60px', textAlign: 'center', fontFamily: 'Nevduplenysh-Regular'}} className='Title'>Активные поездки</h2>
                <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                    <div style={{backgroundImage: `url(${require('../Images/WhereToGo1.png')}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='Section'>
                        <h2 style={{color: 'white'}} className='Title'>5-15 Февраля</h2>
                    </div>
                    <a href='/TripPlanning/1' style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <p style={{color: 'black', backgroundColor: 'white', margin: '10px 0px', textAlign: 'left'}} className='Description_Bold'>Сулакский каньон.</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', marginTop: '0px'}} className='Description'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
                <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                    <div style={{backgroundImage: `url(${require('../Images/WhereToGo1.png')}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='Section'>
                        <h2 style={{color: 'white'}} className='Title'>5-15 Февраля</h2>
                    </div>
                    <a href='/TripPlanning/1' style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <p style={{color: 'black', backgroundColor: 'white', margin: '10px 0px', textAlign: 'left'}} className='Description_Bold'>Сулакский каньон.</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', marginTop: '0px'}} className='Description'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
                <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                    <div style={{backgroundImage: `url(${require('../Images/WhereToGo1.png')}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='Section'>
                        <h2 style={{color: 'white'}} className='Title'>5-15 Февраля</h2>
                    </div>
                    <a href='/TripPlanning/1' style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <p style={{color: 'black', backgroundColor: 'white', margin: '10px 0px', textAlign: 'left'}} className='Description_Bold'>Сулакский каньон.</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', marginTop: '0px'}} className='Description'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
            </div>
            <div style={{backgroundColor: '#4A6118', flexDirection: 'row', gap: '30px', alignItems: 'flex-start', justifyContent: 'space-evenly', flexWrap: 'wrap', padding: '60px 0px'}} className='Section'>
                <h2 style={{color: 'white', width: '100%', fontSize: '80px', lineHeight: '60px', textAlign: 'center', fontFamily: 'Nevduplenysh-Regular'}} className='Title'>Архив поездок</h2>
                <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <div style={{backgroundImage: `url(${require('../Images/WhereToGo1.png')}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='Section'>
                        <h2 style={{color: 'white'}} className='Title'>5-15 Февраля</h2>
                    </div>
                    <a href='/TripPlanning/1' style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <p style={{color: 'black', backgroundColor: 'white', margin: '10px 0px', textAlign: 'left'}} className='Description_Bold'>Сулакский каньон.</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', marginTop: '0px'}} className='Description'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
                <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                    <div style={{backgroundImage: `url(${require('../Images/WhereToGo1.png')}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='Section'>
                        <h2 style={{color: 'white'}} className='Title'>5-15 Февраля</h2>
                    </div>
                    <a href='/TripPlanning/1' style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <p style={{color: 'black', backgroundColor: 'white', margin: '10px 0px', textAlign: 'left'}} className='Description_Bold'>Сулакский каньон.</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', marginTop: '0px'}} className='Description'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
                <div style={{width: '500px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                    <div style={{backgroundImage: `url(${require('../Images/WhereToGo1.png')}`, backgroundPosition: 'bottom', padding: '0px 0px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='Section'>
                        <h2 style={{color: 'white'}} className='Title'>5-15 Февраля</h2>
                    </div>
                    <a href='/TripPlanning/1' style={{color: 'white', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', width: '100%', textAlign: 'center', backgroundColor: 'black'}}>ПОДРОБНЕЕ</a>
                    <p style={{color: 'black', backgroundColor: 'white', margin: '10px 0px', textAlign: 'left'}} className='Description_Bold'>Сулакский каньон.</p>
                    <p style={{color: 'black', backgroundColor: 'white', textAlign: 'left', marginTop: '0px'}} className='Description'>Жизнь полна ярких и чарующих моментов. Читайте наш блог о путешествиях и вдохновляйтесь на создание своих историй!</p>
                </div>
            </div> */}
        </div>
        :
        null
    )
}

export default Account