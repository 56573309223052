import { useState } from "react";
import React from "react";

const slideStyles = {
  width: "calc(20vw + 300px)",
  maxWidth: '90%',
  height: "calc(20vw + 300px)",
  backgroundSize: "contain",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: "center",
};

const rightArrowStyles = {
  position: "absolute",
  top: "100%",
  transform: "translate(0, -50%)",
  right: "32px",
  fontSize: "45px",
  color: "#4A6118",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  position: "absolute",
  top: "100%",
  transform: "translate(0, -50%)",
  left: "32px",
  fontSize: "45px",
  color: "#4A6118",
  zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};

const TeamSlider = ({ slides, title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex]?.image_url})`,
  };

  return (
    <div style={sliderStyles}>
      <div>
        <div onClick={goToPrevious} style={leftArrowStyles}>
          ❰
        </div>
        <div onClick={goToNext} style={rightArrowStyles}>
          ❱
        </div>
      </div>
      <div style={{width: '100%', height: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: "calc(20vw + 300px)", maxWidth: '90%'}}>
          <h2 style={{color: '#4A6118', textAlign: 'left'}} className='Title'>{title}</h2>
          <p style={{marginTop: '30px', textAlign: 'left', lineHeight: '30px', color: 'black', maxWidth: '95%'}}>О гиде</p>
          <p style={{marginTop: '30px', textAlign: 'left', lineHeight: '30px', color: 'black', maxWidth: '95%'}}>
            {
            slides[currentIndex]?.description.split('\n').map((paragraph, index) => (
              <React.Fragment key={index}>
              {paragraph}
              <br />
              </React.Fragment>
            ))
            }
          </p>
        </div>
        <div style={slideStylesWidthBackground}></div>
      </div>
      <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            style={{...dotStyle, color: currentIndex == slideIndex ? '#4A6118' : 'lightgray'}}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSlider;
