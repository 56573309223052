import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function EditAccount({user, setUser}){
    const [avatar, setAvatar] = useState(user?.picture_url);
    const [fullName, setFullName] = useState(user?.full_name);
    const [email, setEmail] = useState(user?.email);
    const [phone, setPhone] = useState(user?.phone);
    const [placeOfResidence, setPlaceOfResidence] = useState(user?.place_of_residence);
    const [error, setError] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
        const fetchData = async () => {
            if (!user) {
                navigate('/SignIn');
            } else {
                setFullName(user?.full_name || '');
                setEmail(user?.email || '');
                setPhone(user?.phone || '');
                setPlaceOfResidence(user?.place_of_residence || '');
                setAvatar(user?.picture_url || '');
            }
        };
        fetchData();
    }, [user, navigate]);

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setAvatar(reader.result)
            setSelectedFile(e.target.files[0])
        };
        reader.readAsDataURL(file);
        }
    };

    const handlePictureUpload = async () => {
        try {
            if(fullName.length > 0 && email.length > 0 && phone.length > 0 && placeOfResidence.length > 0){
                const formData = new FormData();
                formData.append('image', selectedFile);
                var pictureUrl = avatar
            
                const response = await fetch('https://adm.trevatour.ru/api/website/uploadimage.php', {
                    method: 'POST',
                    body: formData,
                });
                
                const responseData = await response.json();
                if(responseData?.image_url){
                    pictureUrl =  responseData?.image_url
                }
        
                const userFormData = new FormData();
                userFormData.append('full_name', fullName);
                userFormData.append('email', email);
                userFormData.append('oldemail', user?.email);
                userFormData.append('hash', user?.hash);
                userFormData.append('place_of_residence', placeOfResidence);
                userFormData.append('phone', phone);
                userFormData.append('picture_url', pictureUrl);
        
                const adduserResponse = await fetch('https://adm.trevatour.ru/api/website/editaccount.php', {
                    method: 'POST',
                    body: userFormData,
                });
        
                const adduserResponseData = await adduserResponse.json();
                if(adduserResponseData?.success){
                    localStorage.setItem('email', adduserResponseData?.user?.email)
                    localStorage.setItem('hash', adduserResponseData?.user?.hash)
                    setUser(adduserResponseData?.user)
                    navigate('/Account')
                }else if(adduserResponseData?.error){
                    setError(adduserResponseData?.error)
                }else{
                    setError('Ошибка')
                }
            }else{
                setError('Все поля обязательны к заполнению!')
            }
        } catch (error) {
          setError(error);
        }
    };

    return(
        <div>
            <div style={{backgroundImage: `url(${require('../Images/Main1.jpg')}`, backgroundPosition: 'top', backgroundColor: '#d6d2c9', padding: '0px'}} className='Section'>
                <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '150px 0px', alignItems: 'center', WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)'}}>
                <h2 style={{color: 'white', fontFamily: 'Nevduplenysh-Regular', fontSize: 'calc(3vw + 40px)', lineHeight: 'calc(3vw + 40px)'}} className='Title'>Редактировать</h2>
                    <form style={{width: '400px', display: 'flex', flexDirection: 'column', marginTop: '50px', rowGap: '40px', maxWidth: '100%'}}>
                        <label htmlFor="files" style={{cursor: 'pointer', alignSelf: 'center'}}>
                            <div style={{backgroundImage: `url(${avatar || 'https://adm.trevatour.ru/media/avatar.jpg'}`, width: '100px', height: '100px', borderRadius: '50px', backgroundRepeat: 'no-repeat', backgroundPosition: 'top', backgroundSize: 'cover'}}></div>
                        </label>
                        <input name='image' onChange={handleAvatarChange} accept='images/*' id="files" style={{display: 'none'}} type="file"/>                        
                        <input name='full_name' onChange={(event) => setFullName(event.target.value)} value={fullName} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="ФИО" type="text" />
                        <input name='email' onChange={(event) => setEmail(event.target.value)} value={email} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Email" type="email" />
                        <input name='phone' onChange={(event) => setPhone(event.target.value)} value={phone} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Номер Телефона" type="text" />
                        <input name='place_of_residence' onChange={(event) => setPlaceOfResidence(event.target.value)} value={placeOfResidence} style={{background: 'none', border: 'none', borderBottom: '2px solid white', padding: '10px', color: 'white', borderRadius: 0}} placeholder="Место проживания" type="text" />
                        {error && <p style={{color: 'red', fontWeight: 'bold'}}>{error}</p>}
                        <a onClick={handlePictureUpload} style={{color: 'black', fontSize: '30px', fontFamily: 'Nevduplenysh-Regular', padding: '10px 0px', maxWidth: '100%', width: '400px', textAlign: 'center', backgroundColor: 'white', cursor: 'pointer'}}>СОХРАНИТЬ</a>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditAccount